.message {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.message .div1 {
  display: flex;
  width: calc(100% - 10rem);
}
.message .div1 .name {
  margin-left: 1rem;
  font-weight: bold;
}
.message .div1 .content {
  margin-left: 1rem;
}
.message .time {
  width: 10rem;
  white-space: nowrap;
  text-align: right;
}
.messageName {
  margin-left: 1rem;
}
.messageContent {
  margin-left: 2rem;
  margin-right: 2rem;
}
.messageTime {
  float: right;
}
.pagination {
  float: right;
}
.cursorPointer :hover {
  color: #1890ff;
  cursor: pointer;
}
.navMO {
  margin: 0 auto;
  height: 3.29rem;
  width: 92%;
  border-bottom: 1px solid #c1bfbf;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navMO > span {
  font-size: 1rem;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #343434;
}
.navMO button {
  color: #fff;
  font-size: 0.75rem;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #ffffff;
}
.contentMO .div1 {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.contentMO .div1 .name {
  font-size: 0.94rem;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #343434;
  flex: 1 1;
}
.contentMO .div1 .time {
  flex: 0 0;
  font-size: 0.63rem;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #343434;
  line-height: 1.91rem;
}
.contentMO .div1 :global .ant-col.ant-col-xs-24.ant-col-sm-24.ant-col-md-20 {
  width: 94%;
}
.contentMO .div1 .content {
  font-size: 0.88rem;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #666666;
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
