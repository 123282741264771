.Content {
  font-family: Microsoft YaHei;
  font-weight: 400;
  /*大会议程*/
  /*赞助商*/
  /*同期展览*/
  /* 合作标题*/
  /*合作单位*/
  /*领导致辞*/
  /*专家演讲*/
  /*精彩活动*/
  /*行业内成果展示*/
  /*展会风采*/
}
.Content .container {
  max-width: 75rem;
  margin: 0 auto;
}
.Content p {
  margin: 0;
}
.Content .colFFF {
  color: #fff;
}
.Content .col000 {
  color: #000;
}
.Content .col999 {
  color: #999;
}
.Content .titleStyle {
  font-size: 29px;
  font-weight: 400;
  text-align: center;
  margin: 30px auto;
}
.Content .titleEnStyle {
  font-size: 17px;
  text-align: center;
  margin: 10px 0 50px;
}
.Content .introductionBox {
  padding: 60px 0;
  color: #666;
}
.Content .introductionBox pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: justify;
  font-size: 21px;
}
.Content .introductionBox pre .moreBtn {
  color: #027ac2;
  cursor: pointer;
}
.Content .introductionBox pre .moreBtn:hover {
  color: #23527c;
  text-decoration: underline;
}
.Content .introductionBox .aboutInfo {
  margin: 80px 0 30px;
  display: flex;
  justify-content: space-between;
}
.Content .introductionBox .aboutInfo .item {
  display: inline-block;
  vertical-align: top;
  width: 23%;
}
.Content .introductionBox .aboutInfo .item .num {
  line-height: 1.42857143;
  font-size: 16px;
  box-sizing: border-box;
  touch-action: pan-y;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 160px;
  height: 160px;
  color: #666;
  position: relative;
  margin: 0 auto;
  border-radius: 50%;
  box-shadow: 0px 2px 21px 0px rgba(62, 115, 234, 0.42);
}
.Content .introductionBox .aboutInfo .item .num .numN {
  vertical-align: top;
  display: inline-block;
  width: 39px;
  height: 68px;
  background: url(../assets/image/123456.png) no-repeat;
  background-size: 100% 1250%;
  background-position-x: center;
  -webkit-transition: background-position-y 2s ease;
  transition: background-position-y 2s ease;
  background-position-y: 100%;
  margin-right: -0.7rem;
}
.Content .introductionBox .aboutInfo .item .num .num3 {
  background-position-y: 73%;
}
.Content .introductionBox .aboutInfo .item .num .num4 {
  background-position-y: 63.5%;
}
.Content .introductionBox .aboutInfo .item .num .num5 {
  background-position-y: 54%;
}
.Content .introductionBox .aboutInfo .item .num .num1 {
  background-position-y: 92%;
}
.Content .introductionBox .aboutInfo .item .num .numW {
  background-position-y: -3%;
  margin-right: 1px;
}
.Content .introductionBox .aboutInfo .item .num .num0 {
  background-position-y: 101.5%;
}
.Content .introductionBox .aboutInfo .item .num .plus {
  display: inline-block;
  vertical-align: top;
  width: 1.5rem;
  height: 1.5rem;
  background: url(../assets/image/icon_add.png) no-repeat;
  background-size: 100%;
  -webkit-transform: translate(-5px, -3px);
  transform: translate(-5px, -3px);
}
.Content .introductionBox .aboutInfo .item .des {
  position: relative;
  text-align: center;
  font-size: 1.3rem;
  margin-top: 1.9rem;
}
.Content .guestsBox {
  position: static;
  background: url(../assets/image/jiabing_bg.png) no-repeat;
  overflow: hidden;
  padding: 60px 0;
}
.Content .guestsBox .tab {
  border: 1px solid #ffffff;
  border-radius: 5px;
  max-width: 410px;
  margin: 10px auto;
  display: inline-block;
  margin-left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.Content .guestsBox .tab .bg {
  background: rgba(255, 255, 255, 0.21);
}
.Content .guestsBox .tab span {
  display: inline-block;
  text-align: center;
  height: 50px;
  width: 203px;
  line-height: 50px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}
.Content .guestsBox .tab span:hover {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.21);
}
.Content .guestsBox .swiperBox {
  position: relative;
}
.Content .guestsBox .swiperBox .jiantouBox {
  position: absolute;
  font-size: 2rem;
  z-index: 2;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
}
.Content .guestsBox .swiperBox .jiantouBox .jiantouImgBox1 {
  width: 40px;
  height: 40px;
  background-image: url("../assets/image/icon_jiantou_left.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.Content .guestsBox .swiperBox .jiantouBox .jiantouImgBox2 {
  width: 40px;
  height: 40px;
  background-image: url("../assets/image/icon_jiantou_right.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.Content .guestsBox .swiperBox .jiantouBox1 {
  left: -50px;
}
.Content .guestsBox .swiperBox .jiantouBox1:hover .jiantouImgBox1 {
  background-image: url("../assets/image/icon_jiantou_left_d.png");
}
.Content .guestsBox .swiperBox .jiantouBox2 {
  right: -50px;
}
.Content .guestsBox .swiperBox .jiantouBox2:hover .jiantouImgBox2 {
  background-image: url("../assets/image/icon_jiantou_right_d.png");
}
.Content .guestsBox .swiperBox .swpiperItem .guestBox {
  color: #fff;
  max-width: 205px;
  font-size: 13px;
  text-align: center;
  padding: 2.7rem 0;
}
.Content .guestsBox .swiperBox .swpiperItem .guestBox .imgBox {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 2px solid #449ef4;
  overflow: hidden;
  margin: 0 auto;
}
.Content .guestsBox .swiperBox .swpiperItem .guestBox .imgBox img {
  width: 100%;
}
.Content .guestsBox .swiperBox .swpiperItem .guestBox .name {
  margin: 18px 0 10px;
  font-size: 22px;
}
.Content .agendaBox {
  padding: 60px 0;
}
.Content .agendaBox .hide {
  position: relative;
}
.Content .agendaBox .hide .agendaShow {
  position: absolute;
  bottom: -75px;
  left: 0;
  height: 75px;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.14);
}
.Content .agendaBox .hide .agendaShow i {
  margin: 10px;
  font-size: 22px;
}
.Content .agendaBox .agendaCon {
  width: 100%;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.14);
  border-radius: 7px;
  overflow: hidden;
  margin-bottom: 40px;
  box-shadow: 0rem 0rem 2rem 0rem rgba(0, 0, 0, 0.14);
}
.Content .agendaBox .agendaCon .header {
  width: 100%;
  height: 93px;
  padding: 0 50px;
  line-height: 93px;
  color: #fff;
  font-size: 21px;
  background: linear-gradient(86deg, #000719, #0b095c, #0f62bf, #6048bd, #030616);
}
.Content .agendaBox .agendaCon .header .divider {
  margin: 0 12px;
}
.Content .agendaBox .agendaCon .agendaList {
  width: 100%;
}
.Content .agendaBox .agendaCon .agendaList .item {
  border-bottom: 1px solid #e3e1e1;
  padding: 0 10px;
  font-size: 24px;
  color: #666;
  overflow: hidden;
}
.Content .agendaBox .agendaCon .agendaList .item :global(.ant-row) {
  display: flex;
  align-items: center;
}
.Content .agendaBox .agendaCon .agendaList .item .left {
  height: 100%;
  width: 66.6666667%;
  border-right: 1px solid #e3e1e1;
  padding: 35px 33.5px;
}
.Content .agendaBox .agendaCon .agendaList .item .left .time {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.Content .agendaBox .agendaCon .agendaList .item .left .time img {
  width: 19px;
  height: 19px;
  margin-right: 9px;
}
.Content .agendaBox .agendaCon .agendaList .item .left .tit {
  font-size: 21px;
}
.Content .agendaBox .agendaCon .agendaList .item .right {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 35px 33.5px 35px 56px;
}
.Content .sponsorBox {
  padding: 60px 0;
  background-color: #f5f5f5;
}
.Content .sponsorBox .swiperBox {
  padding: 30px 0 60px;
}
.Content .sponsorBox .swiperBox :global(.ant-carousel .slick-dots-bottom) {
  bottom: -15px;
}
.Content .sponsorBox .swiperBox :global(.ant-carousel .slick-dots li button) {
  background-color: #e8e9ea;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
.Content .sponsorBox .swiperBox :global(.ant-carousel .slick-dots li.slick-active button) {
  background-color: #0e86ec;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
.Content .sponsorBox .swiperBox .sponsorItem {
  text-align: center;
  font-size: 19px;
}
.Content .sponsorBox .swiperBox .sponsorItem .imgBox {
  overflow: hidden;
  display: inline-block;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);
  height: 60px;
  text-align: center;
  max-width: 100%;
}
.Content .sponsorBox .swiperBox .sponsorItem .imgBox img {
  height: 100%;
  max-width: 100%;
}
.Content .sponsorBox .swiperBox .sponsorItem .name {
  color: #333;
  margin: 25px 0 3px;
}
.Content .sponsorBox .btnBox {
  width: 258px;
  height: 56px;
  margin: 0 auto;
  background: #0e86ec;
  opacity: 0.9;
  border-radius: 28px;
  text-align: center;
  color: #fff;
  line-height: 56px;
  font-size: 26px;
  cursor: pointer;
}
.Content .latestNewsBox a {
  display: block;
}
.Content .latestNewsBox img {
  width: 100%;
}
.Content .samePeriodBox {
  padding: 60px 0;
}
.Content .samePeriodBox .con {
  margin-top: 60px;
}
.Content .samePeriodBox .con .itemBox {
  cursor: pointer;
  font-size: 22px;
  color: #0e86ec;
  width: 100%;
}
.Content .samePeriodBox .con .itemBox .imgBox {
  width: 100%;
  overflow: hidden;
  max-height: 9.5rem;
  margin-bottom: 1.2rem;
}
.Content .samePeriodBox .con .itemBox .imgBox img {
  width: 100%;
}
.Content .samePeriodBox .con .itemBox:hover {
  color: #23527c;
}
.Content .addressBox {
  padding: 60px 0;
}
.Content .addressBox .addressImgBox {
  width: 100%;
}
.Content .addressBox .addressImgBox img {
  width: 100%;
}
.Content .cooperationTit {
  font-size: 29px;
  font-weight: 400;
  color: #333;
  position: relative;
  margin: 0 auto;
  width: 200px;
  text-align: center;
  margin-bottom: 30px;
}
.Content .cooperationTit::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: -110px;
  width: 130px;
  max-width: 400px;
  height: 1px;
  background-color: #d1cfcf;
}
.Content .cooperationTit::before {
  content: " ";
  position: absolute;
  top: 50%;
  left: -110px;
  width: 130px;
  max-width: 400px;
  height: 1px;
  background-color: #d1cfcf;
}
.Content .cooperationUnitBox {
  padding: 60px 0;
}
.Content .cooperationUnitBox .imgBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.Content .cooperationUnitBox .imgBox img {
  max-height: 6rem;
  margin: 0.6rem 0.65rem;
  position: relative;
  top: 0;
  -webkit-transition: top 0.3s;
  transition: top 0.3s;
}
.Content .cooperationUnitBox .imgBox img:hover {
  top: -8px;
}
.Content .speakWrap {
  padding: 60px;
}
.Content .speakWrap .speak {
  margin: 0 auto;
  max-width: 75rem;
  background: #ffffff;
  box-shadow: 0px 0px 28px 0px rgba(0, 0, 0, 0.14);
  border-radius: 16px;
  padding: 33px 44px 0px;
  overflow: hidden;
}
.Content .speakWrap .speak img {
  margin-top: 9px;
  width: 100%;
  max-height: 10.5rem;
  border-radius: 8px;
}
.Content .speakWrap .speak .marginBot .spTitle {
  display: flex;
  margin-bottom: 13px;
}
.Content .speakWrap .speak .marginBot .spTitle .spSpan {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #343434;
  margin-right: 44px;
}
.Content .speakWrap .speak .marginBot .spTitle .spSpan1 {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.Content .speakWrap .speak .marginBot .spTitle .spSpan2 {
  flex: 1 1 auto;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  min-width: 80px;
  margin: 0 10px 0 0;
}
.Content .speakWrap .speak .marginBot .spTitle .spSpan3 {
  margin: 0 auto;
  min-width: 90px;
  height: 25px;
  border: 1px solid #3699ff;
  border-radius: 5px;
  text-align: center;
  line-height: 25px;
  color: #3699ff;
  cursor: pointer;
  font-size: 14px;
}
.Content .speakWrap .speak .marginBot .spTitle div > p {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}
.Content .speakWrap .speak .marginBot .content {
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  height: 8.5em;
  font-size: 16px;
  line-height: 1.7;
  overflow: hidden;
  position: relative;
}
.Content .speakWrap .speak .marginBot .content p {
  line-height: 1.7 !important;
}
.Content .expertSpeech {
  padding: 60px 0;
}
.Content .expertSpeech .speakWrap {
  padding: 0;
  margin: 60px 0;
  position: relative;
}
.Content .expertSpeech .speakWrap .speak {
  padding-bottom: 45px;
}
.Content .expertSpeech .open {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: calc(50% - 600px);
  max-width: 75rem;
  height: 74px;
  background-color: #fff;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Content .expertSpeech .open i {
  margin: 5px;
}
.Content .wonderfulActivities {
  width: 100%;
  padding: 60px 0;
  border-radius: 16px;
}
.Content .wonderfulActivities .wACenter {
  margin: 0 auto;
  max-width: 75rem;
}
.Content .wonderfulActivities .wACenter .wASertion {
  margin-bottom: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  box-shadow: 0px 0px 28px 0px rgba(0, 0, 0, 0.14);
}
.Content .wonderfulActivities .wACenter .wASertion .title {
  width: 100%;
  height: 85px;
  background: linear-gradient(86deg, #000719, #0b095c, #0f62bf, #6048bd, #030616);
  border-radius: 6px 6px 0 0;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 85px;
  text-align: center;
}
.Content .wonderfulActivities .wACenter .wASertion .image {
  width: 100%;
  padding: 50px 160px;
}
.Content .wonderfulActivities .wACenter .wASertion .image img {
  width: 100%;
}
.Content .wonderfulActivities .wACenter .wASertion .addPadding {
  width: 100%;
  padding: 50px 160px;
  height: 1844px;
  position: relative;
  overflow: hidden;
}
.Content .wonderfulActivities .wACenter .wASertion .addPadding img {
  width: 100%;
}
.Content .wonderfulActivities .wACenter .wASertion .addPadding .more {
  position: absolute;
  bottom: 0px;
  width: calc(100% - 320px);
  height: 50px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-self: center;
}
.Content .wonderfulActivities .wACenter .wASertion .addPadding .more span {
  line-height: 50px;
  cursor: pointer;
}
.Content .wonderfulActivities .wACenter .wASertion .footerText {
  margin: 0 auto;
  width: 100%;
  padding: 20px 160px;
  text-align: left;
}
.Content .chievementShow {
  max-width: 75rem;
  margin: 0 auto;
  padding: 60px 0;
}
.Content .chievementShow .title {
  width: 100%;
  height: 85px;
  background: linear-gradient(86deg, #000719, #0b095c, #0f62bf, #6048bd, #030616);
  border-radius: 8px 8px 0 0;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 85px;
  text-align: center;
}
.Content .chievementShow .swiper {
  box-shadow: 0rem 0rem 2rem 0rem rgba(0, 0, 0, 0.14);
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  padding: 0 60px;
}
.Content .chievementShow .swiper .left {
  position: absolute;
  left: 30px;
  top: 40%;
  font-size: 24px;
  z-index: 1;
}
.Content .chievementShow .swiper .right {
  position: absolute;
  right: 30px;
  top: 40%;
  font-size: 24px;
  z-index: 1;
}
.Content .chievementShow .swiper .swipPage {
  height: 65px;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  border-radius: 0 0 16px 16px;
}
.Content .chievementShow .swiper .rowBox {
  padding: 70px 20px 20px;
  display: flex;
}
.Content .chievementShow .swiper .achbox {
  width: 90%;
  margin: 0 auto;
}
.Content .chievementShow .swiper .achbox img {
  width: 100%;
  max-height: 171px;
  height: auto;
}
.Content .chievementShow .swiper .achbox .name {
  margin-top: 10px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
  text-align: left;
}
.Content .chievementShow .sectionShow {
  padding: 71px 70px 60px;
}
.Content .chievementShowFC {
  padding: 60px 0;
}
.Content .chievementShowFC .title {
  width: 100%;
  height: 85px;
  background: linear-gradient(86deg, #000719, #0b095c, #0f62bf, #6048bd, #030616);
  border-radius: 8px 8px 0 0;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 85px;
  text-align: center;
}
.Content .chievementShowFC .swiper {
  position: relative;
  max-width: 75rem;
  margin: 0 auto;
  box-shadow: 0rem 0rem 2rem 0rem rgba(0, 0, 0, 0.14);
  padding: 0 60px;
  border-radius: 8px;
}
.Content .chievementShowFC .swiper .left {
  position: absolute;
  left: 30px;
  top: 40%;
  font-size: 24px;
  z-index: 1;
}
.Content .chievementShowFC .swiper .right {
  position: absolute;
  right: 30px;
  top: 40%;
  font-size: 24px;
  z-index: 1;
}
.Content .chievementShowFC .swiper .swipPage {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 16px 16px;
}
.Content .chievementShowFC .swiper .rowBox {
  padding: 70px 20px 30px;
  display: flex;
}
.Content .chievementShowFC .swiper .achbox {
  width: 90%;
  height: 10.65rem;
  margin: 0 auto;
}
.Content .chievementShowFC .swiper .achbox img {
  width: 100%;
  max-height: 10.65rem;
}
.Content .chievementShowFC .swiper .achbox .name {
  margin-top: 10px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
  text-align: center;
}
.Content .chievementShowFC .sectionShow {
  padding: 71px 70px 60px;
}
.Content .guesBox .speak {
  position: relative;
  padding-bottom: 45px;
}
.Content .guesBox .speak .agendaShow {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4rem;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Content .guesBox .speak .agendaShow i {
  margin: 10px;
  font-size: 22px;
}
.ContentMo {
  font-family: Microsoft YaHei;
  font-weight: 400;
  /*赞助商*/
  /*同期展览*/
  /* 合作标题*/
  /*合作单位*/
  /*大会介绍*/
  /*领导致辞*/
  /*专家演讲*/
  /*精彩活动*/
  /*行业内成果展示*/
  /*嘉宾专访*/
  /*展会风采*/
  /*大会议程*/
}
.ContentMo .container {
  max-width: 75rem;
  margin: 0 auto;
  padding: 0 11px;
}
.ContentMo p {
  margin: 0;
}
.ContentMo .colFFF {
  color: #fff;
}
.ContentMo .col000 {
  color: #000;
}
.ContentMo .col999 {
  color: #999;
}
.ContentMo .titleStyle {
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  margin: 20px auto 10px;
}
.ContentMo .titleEnStyle {
  font-size: 10px;
  text-align: center;
  margin: 0 0 16px;
}
.ContentMo .introductionBox {
  padding: 30px 0;
  color: #666;
}
.ContentMo .introductionBox pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: justify;
  font-size: 14px;
}
.ContentMo .introductionBox pre .moreBtn {
  color: #027ac2;
  cursor: pointer;
}
.ContentMo .introductionBox pre .moreBtn:hover {
  color: #23527c;
  text-decoration: underline;
}
.ContentMo .introductionBox .aboutInfo {
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
}
.ContentMo .introductionBox .aboutInfo .item {
  display: inline-block;
  vertical-align: top;
  width: 23%;
}
.ContentMo .introductionBox .aboutInfo .item .num {
  line-height: 1.42857143;
  font-size: 16px;
  box-sizing: border-box;
  touch-action: pan-y;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 50px;
  height: 50px;
  color: #666;
  position: relative;
  margin: 0 auto;
  border-radius: 50%;
  box-shadow: 0px 1px 14px 0px rgba(62, 115, 234, 0.42);
}
.ContentMo .introductionBox .aboutInfo .item .num .numN {
  vertical-align: top;
  display: inline-block;
  width: 12px;
  height: 23px;
  background: url(../assets/image/123456.png) no-repeat;
  background-size: 100% 1250%;
  background-position-x: center;
  -webkit-transition: background-position-y 2s ease;
  transition: background-position-y 2s ease;
  background-position-y: 100%;
  margin-right: -3px;
}
.ContentMo .introductionBox .aboutInfo .item .num .num3 {
  background-position-y: 72.9%;
}
.ContentMo .introductionBox .aboutInfo .item .num .num4 {
  background-position-y: 63.5%;
}
.ContentMo .introductionBox .aboutInfo .item .num .num5 {
  background-position-y: 53.9%;
}
.ContentMo .introductionBox .aboutInfo .item .num .num1 {
  background-position-y: 91.9%;
}
.ContentMo .introductionBox .aboutInfo .item .num .numW {
  background-position-y: -2.9%;
}
.ContentMo .introductionBox .aboutInfo .item .num .num0 {
  background-position-y: 101.5%;
}
.ContentMo .introductionBox .aboutInfo .item .num .plus {
  display: inline-block;
  vertical-align: top;
  width: 9px;
  height: 9px;
  background: url(../assets/image/icon_add.png) no-repeat;
  background-size: 100%;
}
.ContentMo .introductionBox .aboutInfo .item .des {
  position: relative;
  text-align: center;
  font-size: 12px;
  margin-top: 7px;
}
.ContentMo .guestsBox {
  position: static;
  background: url(../assets/image/jiabing_bg.png) no-repeat;
  overflow: hidden;
  padding: 30px 0;
}
.ContentMo .guestsBox .tab {
  border: 1px solid #ffffff;
  border-radius: 5px;
  width: 242px;
  margin: 10px auto;
  display: inline-block;
  margin-left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.ContentMo .guestsBox .tab .bg {
  background: rgba(255, 255, 255, 0.21);
}
.ContentMo .guestsBox .tab span {
  display: inline-block;
  text-align: center;
  height: 31px;
  width: 120px;
  line-height: 31px;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
}
.ContentMo .guestsBox .tab span:hover {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.21);
}
.ContentMo .guestsBox .swiperBox {
  position: relative;
}
.ContentMo .guestsBox .swiperBox .jiantouBox {
  position: absolute;
  font-size: 13px;
  z-index: 2;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
}
.ContentMo .guestsBox .swiperBox .jiantouBox .jiantouImgBox1 {
  width: 40px;
  height: 40px;
  background-image: url("../assets/image/icon_jiantou_left.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.ContentMo .guestsBox .swiperBox .jiantouBox .jiantouImgBox2 {
  width: 40px;
  height: 40px;
  background-image: url("../assets/image/icon_jiantou_right.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.ContentMo .guestsBox .swiperBox .jiantouBox1 {
  left: -50px;
}
.ContentMo .guestsBox .swiperBox .jiantouBox1:hover .jiantouImgBox1 {
  background-image: url("../assets/image/icon_jiantou_left_d.png");
}
.ContentMo .guestsBox .swiperBox .jiantouBox2 {
  right: -50px;
}
.ContentMo .guestsBox .swiperBox .jiantouBox2:hover .jiantouImgBox2 {
  background-image: url("../assets/image/icon_jiantou_right_d.png");
}
.ContentMo .guestsBox .swiperBox .swpiperItem .guestBox {
  color: #fff;
  max-width: 205px;
  font-size: 9px;
  text-align: center;
  padding: 2.7rem 0;
}
.ContentMo .guestsBox .swiperBox .swpiperItem .guestBox .imgBox {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid #449ef4;
  overflow: hidden;
  margin: 0 auto;
}
.ContentMo .guestsBox .swiperBox .swpiperItem .guestBox .imgBox img {
  width: 100%;
}
.ContentMo .guestsBox .swiperBox .swpiperItem .guestBox .name {
  margin: 13px 0 10px;
  font-size: 13px;
}
.ContentMo .agendaBox {
  padding: 30px 0;
}
.ContentMo .agendaBox .agendaCon {
  width: 100%;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 40px;
}
.ContentMo .agendaBox .agendaCon .header {
  width: 100%;
  height: 54px;
  padding: 0 19px;
  line-height: 54px;
  color: #fff;
  font-size: 12px;
  background: linear-gradient(86deg, #000719, #0b095c, #0f62bf, #6048bd, #030616);
}
.ContentMo .agendaBox .agendaCon .header .divider {
  margin: 0 12px;
}
.ContentMo .agendaBox .agendaCon .agendaList {
  width: 100%;
}
.ContentMo .agendaBox .agendaCon .agendaList :global(.ant-collapse-item-disabled .icon) {
  display: none;
}
.ContentMo .agendaBox .agendaCon .agendaList .item {
  font-size: 14px;
  color: #666;
  overflow: hidden;
}
.ContentMo .agendaBox .agendaCon .agendaList .item .left {
  max-width: calc(100% - 20px);
}
.ContentMo .agendaBox .agendaCon .agendaList .item .left .time {
  margin-bottom: 9px;
  display: flex;
  align-items: center;
  line-height: 14px;
}
.ContentMo .agendaBox .agendaCon .agendaList .item .left .time img {
  width: 11px;
  height: 11px;
  margin-right: 5px;
}
.ContentMo .agendaBox .agendaCon .agendaList .item .left .tit {
  font-size: 12px;
  line-height: 12px;
}
.ContentMo .agendaBox .agendaCon .agendaList .item .jiantouIcon {
  width: 13px;
  height: 13px;
}
.ContentMo .agendaBox .agendaCon .agendaList .item :global(.ant-collapse-header) {
  background: #fff;
  color: #666;
  padding: 15px 13px;
  position: relative;
}
.ContentMo .agendaBox .agendaCon .agendaList .item :global(.ant-collapse-content > .ant-collapse-content-box) {
  background: #ecedfb;
  padding: 12px 14px 14px 12px;
  font-size: 12px;
  color: #666;
}
.ContentMo .sponsorBox {
  padding: 30px 0;
  background-color: #f5f5f5;
}
.ContentMo .sponsorBox .swiperBox {
  padding: 20px 0;
}
.ContentMo .sponsorBox .swiperBox :global(.ant-carousel .slick-dots-bottom) {
  bottom: -5px;
}
.ContentMo .sponsorBox .swiperBox :global(.ant-carousel .slick-dots li button) {
  background-color: #e8e9ea;
  width: 4px;
  height: 4px;
  border-radius: 50%;
}
.ContentMo .sponsorBox .swiperBox :global(.ant-carousel .slick-dots li.slick-active button) {
  background-color: #0e86ec;
  width: 4px;
  height: 4px;
  border-radius: 50%;
}
.ContentMo .sponsorBox .swiperBox .sponsorItem {
  text-align: center;
  font-size: 11px;
}
.ContentMo .sponsorBox .swiperBox .sponsorItem .imgBox {
  overflow: hidden;
  display: inline-block;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);
  height: 36px;
  max-width: 100%;
  text-align: center;
}
.ContentMo .sponsorBox .swiperBox .sponsorItem .imgBox img {
  height: 100%;
  max-width: 100%;
}
.ContentMo .sponsorBox .swiperBox .sponsorItem .name {
  color: #333;
  margin: 13px 0 0;
}
.ContentMo .sponsorBox .btnBox {
  width: 119px;
  height: 30px;
  margin: 0 auto;
  background: #0e86ec;
  opacity: 0.9;
  border-radius: 15px;
  text-align: center;
  color: #fff;
  line-height: 30px;
  font-size: 14px;
  cursor: pointer;
}
.ContentMo .latestNewsBox a {
  display: block;
}
.ContentMo .latestNewsBox img {
  width: 100%;
}
.ContentMo .samePeriodBox {
  padding: 30px 0;
}
.ContentMo .samePeriodBox .con {
  margin-top: 60px;
}
.ContentMo .samePeriodBox .con .itemBox {
  cursor: pointer;
  font-size: 13px;
  color: #0e86ec;
  width: 100%;
}
.ContentMo .samePeriodBox .con .itemBox p {
  margin: 10px 0 27px;
}
.ContentMo .samePeriodBox .con .itemBox .imgBox {
  width: 100%;
  overflow: hidden;
  /*max-height: 100px;*/
}
.ContentMo .samePeriodBox .con .itemBox .imgBox img {
  width: 100%;
  max-height: 100%;
}
.ContentMo .samePeriodBox .con .itemBox:hover {
  color: #23527c;
}
.ContentMo .addressBox {
  padding: 30px 0;
}
.ContentMo .addressBox .addressImgBox {
  width: 100%;
}
.ContentMo .addressBox .addressImgBox img {
  width: 100%;
}
.ContentMo .cooperationTit {
  font-size: 17px;
  font-weight: 400;
  color: #333;
  position: relative;
  margin: 0 auto;
  width: 200px;
  text-align: center;
  margin-bottom: 20px;
}
.ContentMo .cooperationTit::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: -50px;
  width: 100px;
  max-width: 400px;
  height: 1px;
  background-color: #d1cfcf;
}
.ContentMo .cooperationTit::before {
  content: " ";
  position: absolute;
  top: 50%;
  left: -50px;
  width: 100px;
  max-width: 400px;
  height: 1px;
  background-color: #d1cfcf;
}
.ContentMo .cooperationUnitBox {
  padding: 30px 0;
}
.ContentMo .cooperationUnitBox .imgBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.ContentMo .cooperationUnitBox .imgBox img {
  max-height: 1.6rem;
  margin: 0.6rem 0.65rem;
  position: relative;
  top: 0;
  -webkit-transition: top 0.3s;
  transition: top 0.3s;
}
.ContentMo .cooperationUnitBox .imgBox img:hover {
  top: -8px;
}
.ContentMo .TextContent {
  font-size: 0.81rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 1.13rem;
}
.ContentMo .speakWrapMO {
  padding: 0 0.79rem;
  margin: 0 auto;
  border-radius: 0.2rem;
}
.ContentMo .speakWrapMO .speak {
  padding: 0.83rem;
  box-shadow: 0rem 0rem 1rem 0.1rem rgba(0, 0, 0, 0.14);
  border-radius: 0.2rem;
}
.ContentMo .speakWrapMO .speak img {
  width: 4.69rem;
  height: 4.69rem;
}
.ContentMo .speakWrapMO .speak > div {
  margin-bottom: 20px;
}
.ContentMo .speakWrapMO .speak .spSpan1 {
  display: block;
  width: 100%;
  font-size: 0.77rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 0.8rem;
}
.ContentMo .speakWrapMO .speak .spSpan2 {
  font-size: 0.77rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  line-height: 0.8rem;
}
.ContentMo .speakWrapMO .speak .content {
  font-size: 0.66rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #656565;
  line-height: 1.5;
}
.ContentMo .speakWrapMO .speak .content .spSpan3 {
  padding: 1px 5px;
  border: 1px solid #3699ff;
  border-radius: 0.2rem;
  color: #3699ff;
  float: right;
  font-size: 0.5rem;
  text-align: center;
  line-height: 1.1;
}
.ContentMo .expertSpeech .speakWrapMO {
  padding: 0 0.79rem;
  margin: 0 auto;
  border-radius: 0.2rem;
}
.ContentMo .expertSpeech .speakWrapMO .speak {
  padding: 0.83rem 0.83rem 3rem;
  box-shadow: 0rem 0rem 1rem 0.1rem rgba(0, 0, 0, 0.14);
  border-radius: 0.2rem;
  position: relative;
}
.ContentMo .expertSpeech .speakWrapMO .speak img {
  width: 4.69rem;
  height: 4.69rem;
}
.ContentMo .expertSpeech .speakWrapMO .speak > div {
  margin-bottom: 20px;
}
.ContentMo .expertSpeech .speakWrapMO .speak .spSpan {
  margin-right: 0.64rem;
  font-size: 0.78rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 1;
}
.ContentMo .expertSpeech .speakWrapMO .speak .spSpan1 {
  display: block;
  width: 100%;
  font-size: 0.77rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 0.8rem;
}
.ContentMo .expertSpeech .speakWrapMO .speak .spSpan2 {
  font-size: 0.77rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  line-height: 0.8rem;
}
.ContentMo .expertSpeech .speakWrapMO .speak .content {
  font-size: 0.66rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #656565;
  line-height: 1.5;
}
.ContentMo .expertSpeech .speakWrapMO .speak .content .spSpan3 {
  padding: 1px 5px;
  border: 1px solid #3699ff;
  border-radius: 0.2rem;
  color: #3699ff;
  float: right;
  font-size: 0.5rem;
  text-align: center;
  line-height: 1;
}
.ContentMo .expertSpeech .speakWrapMO .speak .open {
  margin: 0;
  padding-top: 1.5rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4rem;
  background-color: #fff;
  text-align: center;
  font-size: 0.63rem;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #333333;
  line-height: 2.25rem;
}
.ContentMo .wonderfulActivities {
  width: 100%;
  padding: 11px;
  margin-top: 2.7rem;
  border-radius: 8px;
}
.ContentMo .wonderfulActivities .wACenter {
  margin: 0 auto;
}
.ContentMo .wonderfulActivities .wACenter .wASertion {
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px 8px 0 0;
  box-shadow: 0px 0px 28px 0px rgba(0, 0, 0, 0.14);
}
.ContentMo .wonderfulActivities .wACenter .wASertion .title {
  width: 100%;
  height: 2rem;
  background: linear-gradient(86deg, #000719, #0b095c, #0f62bf, #6048bd, #030616);
  border-radius: 6px 6px 0 0;
  font-size: 0.7rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 2rem;
  text-align: center;
}
.ContentMo .wonderfulActivities .wACenter .wASertion .image {
  width: 100%;
  padding: 20px 40px;
}
.ContentMo .wonderfulActivities .wACenter .wASertion .image img {
  width: 100%;
}
.ContentMo .wonderfulActivities .wACenter .wASertion .addPadding {
  width: 100%;
  padding: 20px 40px;
  height: 26.9rem;
  overflow: hidden;
  position: relative;
}
.ContentMo .wonderfulActivities .wACenter .wASertion .addPadding img {
  width: 100%;
}
.ContentMo .wonderfulActivities .wACenter .wASertion .addPadding .more {
  position: absolute;
  bottom: 0px;
  width: calc(100% - 80px);
  height: 1.5rem;
  font-size: 12px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-self: center;
}
.ContentMo .wonderfulActivities .wACenter .wASertion .addPadding .more span {
  cursor: pointer;
  line-height: 1.5rem;
}
.ContentMo .wonderfulActivities .wACenter .wASertion .footerText {
  padding: 10px 40px;
  text-align: left;
  font-size: 0.78rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 1.2rem;
  border-radius: 0;
}
.ContentMo .chievementShow {
  margin: 20px 10px 2.72rem;
}
.ContentMo .chievementShow .title {
  width: 100%;
  height: 2rem;
  background: linear-gradient(86deg, #000719, #0b095c, #0f62bf, #6048bd, #030616);
  border-radius: 8px 8px 0 0;
  font-size: 0.7rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 2rem;
  text-align: center;
}
.ContentMo .chievementShow .swiper {
  position: relative;
  box-shadow: 0px 0px 28px 0px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
}
.ContentMo .chievementShow .swiper .left {
  position: absolute;
  left: 0.64rem;
  top: 34%;
  font-size: 1rem;
  z-index: 1;
}
.ContentMo .chievementShow .swiper .right {
  position: absolute;
  right: 0.64rem;
  top: 34%;
  font-size: 1rem;
  z-index: 1;
}
.ContentMo .chievementShow .swiper .swipPage {
  height: 2rem;
  font-size: 0.63rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0e86ec;
  line-height: 1.02rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ContentMo .chievementShow .swiper .rowBox {
  margin: 1.1rem auto 0.3rem;
  width: 19.44rem;
  display: flex;
}
.ContentMo .chievementShow .swiper .imgBox {
  display: flex;
  justify-content: center;
}
.ContentMo .chievementShow .swiper .achbox {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ContentMo .chievementShow .swiper .achbox img {
  width: 7.38rem;
  height: 5.34rem;
}
.ContentMo .chievementShow .swiper .achbox .name {
  width: 7.38rem;
  margin-top: 0.45rem;
  font-size: 0.69rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.ContentMo .guesBox {
  padding: 20px 0;
}
.ContentMo .guesBox .speakWrap {
  padding: 0 0.79rem;
  margin: 0 auto;
  border-radius: 0.2rem;
}
.ContentMo .guesBox .speakWrap .speak {
  padding: 0.83rem 0.83rem 3rem;
  box-shadow: 0rem 0rem 1rem 0.1rem rgba(0, 0, 0, 0.14);
  border-radius: 0.2rem;
  position: relative;
}
.ContentMo .guesBox .speakWrap .speak img {
  width: 4.69rem;
  height: 4.69rem;
}
.ContentMo .guesBox .speakWrap .speak > div {
  margin-bottom: 1rem;
}
.ContentMo .guesBox .speakWrap .speak .spSpan1 {
  display: block;
  width: 100%;
  font-size: 0.77rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 0.8rem;
}
.ContentMo .guesBox .speakWrap .speak .spSpan2 {
  font-size: 0.77rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  line-height: 0.8rem;
}
.ContentMo .guesBox .speakWrap .speak .content {
  font-size: 0.66rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #656565;
  line-height: 1.5;
}
.ContentMo .guesBox .speakWrap .speak .content .spSpan3 {
  padding: 1px 5px;
  border: 1px solid #3699ff;
  border-radius: 0.2rem;
  color: #3699ff;
  float: right;
  font-size: 0.5rem;
  text-align: center;
  line-height: 1;
}
.ContentMo .guesBox .speakWrap .speak .agendaShow {
  margin: 0;
  padding-top: 1.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4rem;
  background-color: #fff;
  text-align: center;
  font-size: 0.63rem;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #333333;
  line-height: 2.25rem;
}
.ContentMo .chievementShowFC {
  margin-bottom: 2.72rem;
  padding: 0 10px;
}
.ContentMo .chievementShowFC .swiper {
  position: relative;
  box-shadow: 0rem 0rem 2rem 0rem rgba(0, 0, 0, 0.14);
  border-radius: 8px;
}
.ContentMo .chievementShowFC .swiper .left {
  position: absolute;
  left: 0.64rem;
  top: 34%;
  font-size: 1rem;
  z-index: 1;
}
.ContentMo .chievementShowFC .swiper .right {
  position: absolute;
  right: 0.64rem;
  top: 34%;
  font-size: 1rem;
  z-index: 1;
}
.ContentMo .chievementShowFC .swiper .swipPage {
  height: 2rem;
  font-size: 0.63rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0e86ec;
  line-height: 1.02rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ContentMo .chievementShowFC .swiper .rowBox {
  margin: 1.1rem auto 0.3rem;
  width: 19.44rem;
  display: flex;
}
.ContentMo .chievementShowFC .swiper .imgBox {
  display: flex;
  justify-content: center;
}
.ContentMo .chievementShowFC .swiper .achbox img {
  width: 7.38rem;
  height: 5.34rem;
}
.ContentMo .chievementShowFC .swiper .achbox .name {
  margin-top: 0.45rem;
  font-size: 0.69rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  text-align: center;
}
.ContentMo .agendaBox .hide {
  position: relative;
}
.ContentMo .agendaBox .hide .agendaShow {
  position: absolute;
  bottom: calc(-3rem + 3px);
  left: 0;
  width: 100%;
  height: 3rem;
  font-size: 0.99rem;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #343434;
  line-height: 3rem;
  background-color: #fff;
  border: 1px solid #e3e2e2;
  border-radius: 0 0 8px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .Content {
    /*领导致辞*/
  }
  .Content .speakWrap {
    padding: 0;
  }
  .Content .speakWrap .marginBot {
    margin-left: 20px;
  }
  .Content .speakWrap .speak {
    margin: 0 auto;
    max-width: 75rem;
    background: #ffffff;
    box-shadow: 0px 0px 28px 0px rgba(0, 0, 0, 0.14);
    border-radius: 16px;
    padding: 33px 44px 0px;
  }
  .Content .speakWrap .speak img {
    width: 8rem;
    height: 8rem;
    border-radius: 8px;
  }
  .Content .speakWrap .speak .marginBot {
    margin-bottom: 40px;
  }
  .Content .speakWrap .speak .marginBot .spTitle {
    display: flex;
  }
  .Content .speakWrap .speak .marginBot .spTitle .spSpan {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #343434;
    line-height: 30px;
    margin-right: 44px;
  }
  .Content .speakWrap .speak .marginBot .spTitle .spSpan1 {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 30px;
  }
  .Content .speakWrap .speak .marginBot .spTitle .spSpan2 {
    flex: 1 1 auto;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    margin-left: 20px;
    line-height: 30px;
  }
  .Content .speakWrap .speak .marginBot .spTitle .spSpan3 {
    margin: 0 auto;
    width: 89px;
    height: 30px;
    border: 1px solid #3699ff;
    border-radius: 5px;
    text-align: center;
    line-height: 30px;
    color: #3699ff;
  }
  .Content .speakWrap .speak .marginBot .content p {
    font-size: 1rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }
  .Content .achbox {
    width: 140px !important;
    margin: 0 auto;
  }
  .Content .achbox img {
    width: 140px !important;
    height: 103px !important;
  }
  .Content .achbox .swipPage {
    height: 40px !important;
  }
  .Content .open {
    left: auto !important;
  }
  .Content .guesBox .speak {
    padding-bottom: 30px;
  }
  .chievementShowFC .left {
    top: 33% !important;
  }
  .chievementShowFC .right {
    top: 33% !important;
  }
  .chievementShowFC .swipPage {
    height: 40px;
  }
}
