.wrap {
  max-width: 62.5rem;
  margin: 0 auto;
  margin-bottom: 50px;
}
.wrap .item {
  display: inline-block;
  margin-top: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  width: 100%!important;
}
.wrap .item .imgWrap {
  width: 80%;
  position: relative;
}
.wrap .item .imgWrap img {
  width: 100%;
}
.wrap .item .imgWrap .play {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 45px;
}
.wrap .item p {
  margin-bottom: 10px;
  min-height: 52px ;
}
.wrap .item .time {
  font-size: 16px;
  text-align: right;
  position: absolute;
  bottom: -10px;
  right: 0px;
}
.wrapMO {
  padding: 0 5px;
  margin: 0 auto;
  margin-bottom: 30px;
}
.wrapMO .item {
  width: 100%!important;
  display: inline-block;
  margin-top: 20px;
  border-bottom: 1px solid #eee;
  width: 100%;
}
.wrapMO .item .imgWrap {
  position: relative;
}
.wrapMO .item .imgWrap img {
  width: 100%;
}
.wrapMO .item .imgWrap .play {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 20px;
}
.wrapMO .item p,
.wrapMO .item h3 {
  padding-left: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wrapMO .item h3 {
  font-size: 17px;
  line-height: 20px;
  margin-bottom: 5px;
}
.wrapMO .item p {
  font-size: 15px;
}
