.comment {
  margin-left: 2rem;
}
.title {
  color: #001529;
  font-size: 13px;
  font-weight: bold;
}
.content {
  margin-top: 5px;
  font-size: 12px;
}
.button {
  font-size: 10px;
  margin-top: 10px;
}
.pagination {
  float: right;
}
