* {
  margin: 0;
  padding: 0;
}
.swipWrap {
  position: relative;
}
.swipWrap .swip {
  width: 100%;
  height: 420px;
  position: relative;
  overflow: hidden;
}
.swipWrap .swip li {
  position: absolute;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  list-style: none;
}
.swipWrap .swip .img1 {
  left: 28%;
  z-index: 3;
}
.swipWrap .swip .img1 .swiperTitle {
  margin: 15px;
  line-height: 30px;
  font-size: 24px;
  text-align: center;
  background-color: #fff;
}
.swipWrap .swip .img2 {
  right: 11%;
  z-index: 2;
  -webkit-transform: scale3d(0.9, 0.9, 0.9);
  transform: scale3d(0.9, 0.9, 0.9);
}
.swipWrap .swip .img3 {
  right: -4.5%;
  z-index: 1;
  -webkit-transform: scale3d(0.8, 0.8, 0.8);
  transform: scale3d(0.8, 0.8, 0.8);
}
.swipWrap .swip .img5 {
  left: -4.5%;
  z-index: 1;
  -webkit-transform: scale3d(0.8, 0.8, 0.8);
  transform: scale3d(0.8, 0.8, 0.8);
}
.swipWrap .swip .img6 {
  left: 11%;
  z-index: 2;
  -webkit-transform: scale3d(0.9, 0.9, 0.9);
  transform: scale3d(0.9, 0.9, 0.9);
}
.swipWrap .swip .img4 {
  left: 30%;
  z-index: 0;
  -webkit-transform: scale3d(0.7, 0.7, 0.7);
  transform: scale3d(0.7, 0.7, 0.7);
}
.swipWrap .swipLift {
  position: absolute;
  font-size: 50px;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  z-index: 4;
  color: #fff;
}
.swipWrap .swipRight {
  position: absolute;
  font-size: 50px;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  z-index: 3;
  color: #fff;
}
.swipWrap .swipMO {
  width: 100%;
  height: 185px;
  position: relative;
}
.swipWrap .swipMO li {
  position: absolute;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  list-style: none;
}
.swipWrap .swipMO .img1 {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 3;
}
.swipWrap .swipMO .img1 .swiperTitleMO {
  margin: 10px;
  font-size: 18px;
  text-align: center;
}
.swipWrap .swipMO .img2 {
  right: 4.5%;
  z-index: 2;
  -webkit-transform: scale3d(0.9, 0.9, 0.9);
  transform: scale3d(0.9, 0.9, 0.9);
}
.swipWrap .swipMO .img3 {
  right: -9%;
  z-index: 1;
  -webkit-transform: scale3d(0.8, 0.8, 0.8);
  transform: scale3d(0.8, 0.8, 0.8);
}
.swipWrap .swipMO .img5 {
  left: -9%;
  z-index: 1;
  -webkit-transform: scale3d(0.8, 0.8, 0.8);
  transform: scale3d(0.8, 0.8, 0.8);
}
.swipWrap .swipMO .img6 {
  left: 4.5%;
  z-index: 2;
  -webkit-transform: scale3d(0.9, 0.9, 0.9);
  transform: scale3d(0.9, 0.9, 0.9);
}
.swipWrap .swipMO .img4 {
  left: -80%;
  z-index: 0;
  -webkit-transform: scale3d(0.7, 0.7, 0.7);
  transform: scale3d(0.7, 0.7, 0.7);
}
.swipWrap .swipLiftMO {
  position: absolute;
  font-size: 25px;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  z-index: 4;
  color: #fff;
}
.swipWrap .swipRightMO {
  position: absolute;
  font-size: 25px;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  z-index: 3;
  color: #fff;
}
