@media only screen and (min-width: 768px) {
  :global(.react-pdf__Page__canvas) {
    margin: 0 auto;
  }
}
@media only screen and (max-width: 768px) {
  :global(.react-pdf__Page__canvas) {
    width: 100% !important;
    height: auto !important;
    margin: 0 auto;
  }
}
.downBox {
  width: 50%;
  padding: 20px;
  margin: auto;
}
.DividingLine {
  height: 0.1rem;
  background-color: #d7d7d7;
  margin-bottom: 0.63rem;
}
.operatingArticleIcon {
  display: flex;
  justify-content: flex-end;
}
.operatingArticleIcon img {
  width: 20px;
  height: 20px;
}
