.wrap {
  width: 100%;
  height: 100%;
  padding: 4.5rem 2rem 0rem;
}
.wrap .hr {
  margin: 0;
  color: #C1BEBE;
  margin-bottom: 2.25rem;
}
.wrap .changeHead {
  height: 0.91rem;
  margin-bottom: 1.88rem;
  display: flex;
  align-items: center;
}
.wrap .changeHead img {
  width: 0.94rem;
  height: 0.94rem;
}
.wrap .changeHead span {
  margin-left: 0.59rem;
  color: #333333;
  font-size: 0.94rem;
  font-weight: 500;
  font-family: Source Han Sans SC;
}
.wrapMO {
  width: 100%;
  height: 100%;
  padding: 2rem 2rem 0rem;
}
.wrapMO .changeHead {
  height: 0.91rem;
  margin-bottom: 1.88rem;
  display: flex;
  align-items: center;
}
.wrapMO .changeHead img {
  width: 0.94rem;
  height: 0.94rem;
}
.wrapMO .changeHead span {
  margin-left: 0.59rem;
  color: #333333;
  font-size: 0.94rem;
  font-weight: 500;
  font-family: Source Han Sans SC;
}
