.comment {
  margin-left: 2rem;
}
.title {
  color: #001529;
  font-size: 13px;
  font-weight: bold;
}
.content {
  margin-top: 5px;
  font-size: 12px;
}
.div1 span {
  font-size: 12px;
  margin-right: 2rem;
}
.pagination {
  float: right;
}
