.wrap .title {
  background: #F1EEEE;
  height: 2.54rem;
}
.wrap .title .center {
  max-width: 75rem;
  margin: 0 auto;
  font-size: 0.79rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 2.54rem;
}
.wrap .container {
  padding: 2.5rem;
  max-width: 75rem;
  min-height: 300px;
  margin: 2rem auto 5rem;
  background: #FFFFFF;
  border: 1px solid #E8E6E6;
  box-shadow: 1px 3px 12px 0px rgba(16, 22, 34, 0.06);
  display: flex;
  flex-direction: column;
}
.wrap .container .section1 {
  margin-bottom: 1.57rem;
  font-size: 1.57rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  line-height: 2.19rem;
}
.wrap .container .section1 p {
  margin: 0;
}
.wrap .container .section1 span {
  margin-right: 1rem;
}
.wrap .container .section2 {
  margin-bottom: 1.61rem;
  display: flex;
  justify-content: space-between;
}
.wrap .container .section2 .left span {
  margin-right: 1.61rem;
  font-size: 0.94rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 1.88rem;
}
.wrap .container .section2 .right {
  font-size: 0.94rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 1.88rem;
}
.wrap .container .section2 .right span {
  margin-left: 0.8rem;
  cursor: pointer;
}
.wrap .container .section2 .right .active {
  color: #1F76EC;
}
.wrap .container .section3 {
  padding-top: 2.08rem;
  border-top: 1px solid #C0BBBB;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #343434;
  line-height: 1.5;
  text-indent: 2rem;
}
.wrap .container .section3 span {
  margin-right: 3rem;
}
.wrapMO .title {
  padding-left: 0.88rem;
  background: #F1EEEE;
  height: 2.1rem;
}
.wrapMO .title .center {
  max-width: 75rem;
  margin: 0 auto;
  font-size: 0.79rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 2.54rem;
}
.wrapMO .container {
  padding: 0 0.88rem;
  max-width: 75rem;
  margin: 1.25rem auto 2.6rem;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
}
.wrapMO .container .section1 {
  margin-bottom: 0.69rem;
  font-size: 1.13rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #343434;
  line-height: 1.57rem;
}
.wrapMO .container .section1 p {
  margin: 0;
}
.wrapMO .container .section1 span {
  margin-right: 1rem;
}
.wrapMO .container .section2 {
  margin-bottom: 1rem ;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.wrapMO .container .section2 .left span {
  margin-right: 1.61rem;
  font-size: 0.75rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 1.57rem;
}
.wrapMO .container .section2 .right {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #343434;
  line-height: 0.74rem;
  display: flex;
  justify-content: center;
}
.wrapMO .container .section2 .right span {
  margin-left: 0.8rem;
}
.wrapMO .container .section3 {
  padding-top: 2.08rem;
  border-top: 1px solid #C0BBBB;
  font-size: 0.94rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #343434;
  line-height: 1.57rem;
  text-indent: 2rem;
}
.wrapMO .container .section3 p {
  margin: 0;
}
