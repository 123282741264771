body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  -webkit-overflow-scrolling: touch;
}
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
#root {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  min-height: 100vh;
  font-size: 1.1rem;
}
.Layout {
  margin: 0 auto;
  max-width: 1920px;
}
/* .TopicItem{
  padding: 0 8px !important;
} */
/* .TopicItem:nth-child(odd){
  padding-left: 0;
  padding-right: 8px;
}
.TopicItem:nth-child(even){
  padding-left: 8px;
  padding-right: 0;
} */
.ant-card-head {
  background: transparent;
  border-radius: 0.2rem;
}
/* ==================== 超过几行省略 ==================== */
